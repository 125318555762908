import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import PillBox from '../../../components/pills/PillBox';
import { getMatrimonialProfilesByMobileNo } from '../../../actions/matrimonialAction';
import ExistingProfileCard from './existingProfileCard';

interface PhoneNumberSearchProps {
  handleVerify: (phoneNumbers: string[]) => void;
  onClose: () => void;
}

const PhoneNumberSearch: React.FC<PhoneNumberSearchProps> = ({ handleVerify, onClose }) => {
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [existingProfiles, setExistingProfiles] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);

  const onVerifyClick = async (e) => {
    e.preventDefault();
    if (phoneNumbers?.length == 0) {
      toast.error('Please enter atleast one Contact No. to verify');
      return;
    }
    try {
      setIsVerifying(true);
      const response = await getMatrimonialProfilesByMobileNo(phoneNumbers);
      console.log('response for get all matrimony profile by phone number', response);
      if (response?.status) {
        setExistingProfiles(response?.profiles);
        if (response?.profiles?.length > 0) {
          toast.error('A profile is already existed for this phone number');
        } else {
          toast.success('No profiles existed, Create New One');
        }
      } else toast.error('An error occurred while fetching details for matrimony profile');
      setIsVerifying(false);
    } catch (error) {
      setIsVerifying(false);
      toast.error(`An error occurred while fetching details for matrimony profile, ${error}`);
    }
  };

  // Function to handle adding a phone number
  const updatePhoneNumbers = (newPhoneNumbers) => {
    console.log('phone no. ', newPhoneNumbers);
    setPhoneNumbers(newPhoneNumbers);
  };

  return (
    <>
      <PillBox
        heading="Search Profiles By Phone Numbers :"
        list={phoneNumbers}
        updatingData={updatePhoneNumbers}
        name="phoneNumbers"
      />
      <Button onClick={onVerifyClick} variant="contained" sx={{ mt: 2 }}>
        {isVerifying ? <CircularProgress color="inherit" size={24} /> : 'Search existing profiles'}
      </Button>
      {existingProfiles?.length > 0 && (
        <>
          {existingProfiles?.map((profile, index) => (
            <ExistingProfileCard profile={profile} key={index} edit={true} />
          ))}
        </>
      )}
    </>
  );
};

export default PhoneNumberSearch;
